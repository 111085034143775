import React from 'react';
import styled from 'styled-components/macro';
import { Route, Switch, Link } from 'react-router-dom';
import { Image } from '../../../../components/Image';
import { Span } from '../../../../components/typography';
import SearchInput from './SearchInput';
import { Div } from '../../../../components/grid';

const CategoryOptionWrapNoStyle = ({ children, ...otherProps }) => (
  <div {...otherProps}>
    {children}
  </div>
);
const CategoryOptionWrap = styled(CategoryOptionWrapNoStyle)`
  overflow: hidden;
  padding-top: 5px;
  
  @media (max-width: 767px) {
    border-bottom: 0;
  }
`;
const BorderBottom = styled.div`
  background: ${({ theme }) => theme.colors.colorGreyLightAlt};
  border-top: 5px solid ${({ theme }) => theme.colors.colorBlue};
  display: block;
  height: 10px;
  width: 100%;

  @media (max-width: 767px) {
    height: 0px;
  }
`;
const CategoryLinkNoStyle = ({
  children,
  to,
  title,
  active,
  returPantOption,
  leveringOption,
  flaskerOption,
  fadoelOption,
  ...otherProps
}) => (
  <Link to={to} title={title} returPantOption={returPantOption} leveringOption={leveringOption} flaskerOption={flaskerOption} fadoelOption={fadoelOption} {...otherProps}>
    {children}
  </Link>
);
const CategoryLink = styled(CategoryLinkNoStyle)`
  display: inline-block;
  text-align: center;
  text-decoration: none;
  transition: background-color .2s ease;
  background-color:  ${({ theme, active }) => (active ? theme.colors.colorGreyLightAlt : theme.colors.colorBlue)};
  border: 5px solid ${({ theme, active }) => (active ? theme.colors.colorBlue : 'none')};
  border-bottom: 0px;
  color:  ${({ theme, active }) => (active ? theme.colors.colorGreyDark : theme.colors.colorWhite)};
  width: ${({ returPantOption, leveringOption }) => ((returPantOption || leveringOption) ? 'auto' : '220px')};
  height: ${({ returPantOption, leveringOption }) => ((returPantOption || leveringOption) ? 'auto' : '132px')};
  padding: ${({ active }) => (active ? '5px' : '10px')};
  position: relative;
  margin-right: 10px;
  border-top-left-radius: ${({ returPantOption, leveringOption }) => ((returPantOption || leveringOption) ? '10px' : '20px')};;
  border-top-right-radius: ${({ returPantOption, leveringOption }) => ((returPantOption || leveringOption) ? '10px' : '20px')};;
  box-shadow: ${({ active }) => (active ? '' : '0 0 30px rgba(0,0,0,0.3) inset')};
  opacity: ${({ active }) => (active ? '1' : '0.7')};
  top: ${({ returPantOption, leveringOption }) => ((returPantOption || leveringOption) ? '8px' : '7px')};
  margin-bottom: 2px;

  &:after {
    content: "";
    display: block;
    width: 100%;
    position: absolute;
    background-color:  ${({ theme, active }) => (active ? theme.colors.colorGreyLightAlt : theme.colors.colorBlue)};
    height: 5px;
    left: 0;
    bottom: ${({ active }) => (active ? '-5px' : '0')};
  }

  &:hover {
    opacity: 1;
  }

  & span {
    font-size: 16px;
    font-weight: bold;
  }
  @media (max-width: 1199px) {
    width: ${({ returPantOption, leveringOption }) => ((returPantOption || leveringOption) ? 'auto' : '170px')};
  }
  @media (max-width: 991px) {
    margin-right: 5px;
    top: ${({ returPantOption, leveringOption }) => ((returPantOption || leveringOption) ? '12px' : '')};

    & span {
      font-size: ${({ returPantOption, leveringOption }) => ((returPantOption || leveringOption) ? '14px' : 'inherit')};
      padding-right: ${({ returPantOption, leveringOption }) => ((returPantOption || leveringOption) ? '0' : '')};
      padding-left: ${({ returPantOption, leveringOption }) => ((returPantOption || leveringOption) ? '0' : '')};
    }
  }
  
  @media (max-width: 767px) {
  ${({
    flaskerOption,
    fadoelOption,
    returPantOption,
    leveringOption,
  }) => {
    if (flaskerOption) {
      return `
        width: calc(50% - 25px);
      `;
    } if (fadoelOption) {
      return `
        margin-right: 0px;
        width: calc(50% - 20px);
      `;
    } if (returPantOption || leveringOption) {
      return `
        display: none;
      `;
    }
    return '';
  }}
  
  @media (max-width: 575px) {
    & span {
      font-size: 14px;
    }
  }
`;
const CategoryOption = ({
  id,
  active,
  title,
  to,
  image,
  returPantOption,
  leveringOption,
  flaskerOption,
  fadoelOption,
}) => (
  <React.Fragment>
    <CategoryLink id={id} to={to} returPantOption={returPantOption} leveringOption={leveringOption} flaskerOption={flaskerOption} fadoelOption={fadoelOption} disabled={active} active={active}>
      {!returPantOption && !leveringOption && (
        <Div textCenter>
          <Image name={image} />
        </Div>
      )}
      <Span textCenter padding="10px">
        {title}
      </Span>
    </CategoryLink>
  </React.Fragment>
);


const CategorySelector = ({ activeIndex }) => (
  <CategoryOptionWrap>
    <CategoryOption id="overCat_flasker-og-daaser" flaskerOption active={activeIndex === 0} title="Flasker &amp; dåser" to="/bestil/flasker-og-daaser" image="bestil.flaskerogdaaser1.png" />
    <CategoryOption id="overCat_fadoel-og-anlaeg" fadoelOption active={activeIndex === 1} title="Fadøl &amp; anlæg" to="/bestil/fadoel-og-anlaeg" image="bestil.fadoeloganlaeg1.png" />
    <CategoryOption id="overCat_levering" leveringOption active={activeIndex === 2} title="Levering" to="/bestil/levering" />
    <SearchInput />
    <BorderBottom />
  </CategoryOptionWrap>
);

const createCategorySelector = activeIndex => props => <CategorySelector activeIndex={activeIndex} {...props} />;

export default () => (
  <Switch>
    <Route path="/bestil/flasker-og-daaser" render={createCategorySelector(0)} />
    <Route path="/bestil/fadoel-og-anlaeg" render={createCategorySelector(1)} />
    <Route path="/bestil/levering" render={createCategorySelector(2)} />
    <Route path="/bestil/returpant" render={createCategorySelector(3)} />
    <Route render={createCategorySelector(-1)} />
  </Switch>
);
