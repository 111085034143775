import React from 'react';
import styled from 'styled-components/macro';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router';
import {
  getIsSearching,
  getSearchTerm,
  clearSearch,
  changeSearch,
} from '../../../../features/commerce/search';
import { Paragraph } from '../../../../components/typography';

const SpecialParagraphNoStyle = ({
  children,
  ...otherProps
}) => (
  <Paragraph {...otherProps}>
    {children}
  </Paragraph>
);
const SpecialParagraph = styled(SpecialParagraphNoStyle)`
  font-size: 12px;
  text-align: center;

  @media screen and (max-width: 1199px) {
    font-size: 10px;
  }
  @media screen and (max-width: 991px) {
    display: none;
  }
`;
const SearchIcon = styled.div`
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  transform: scale(var(--ggs,1));
  width: 20px;
  height: 20px;
  border: 3px solid ${({ theme }) => theme.colors.colorBlue};
  border-radius: 100%;
  margin-left: -5px;
  margin-top: 0px;

  &:after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    border-radius: 3px;
    width: 3px;
    height: 10px;
    background: ${({ theme }) => theme.colors.colorBlue};
    transform: rotate(-45deg);
    top: 12px;
    left: 15px;
  }
`;
const InputWrapperNoStyle = ({
  children,
  ...otherProps
}) => (
  <div {...otherProps}>
    <SpecialParagraph marginTop="0">
      <SearchIcon />
    </SpecialParagraph>
    <SpecialParagraph marginTop="0">
      Kan du ikke finde varen du leder efter?
    </SpecialParagraph>
    {children}
  </div>
);
const InputWrapper = styled(InputWrapperNoStyle)`
  border: 5px solid ${({ theme }) => theme.colors.colorGreyLightAlt};
  border-bottom: 0px;
  padding: 15px 30px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: block;
  float: right;
  text-align: center;
  height: 110px;
  position: relative;
  bottom: -9px;
  
  @media (max-width: 1199px) {
    padding: 15px 10px;
    width: 160px;
  }
  
  @media (max-width: 991px) {
    border: 0;
    padding: 0;
    bottom: -12px;
    height: 140px;
    position: relative;
    text-align: left;
  }
  
  @media (max-width: 767px) {
    margin-top: 20px;
    height: auto;
    bottom: 0;
    width: 100%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  
  @media (max-width: 575px) {
  }
`;

const Input = styled.input`
  border: 2px solid ${({ theme }) => theme.colors.colorGreyLightAlt};
  outline: 0;
  padding: 11px 15px;
  width: calc(100% - 34px);
  
  @media (max-width: 1199px) {
  }
  
  @media (max-width: 991px) {
    border: 5px solid ${({ theme }) => theme.colors.colorGreyLightAlt};
    border-bottom: 0px;
    position: absolute;
    padding: 15px;
    width: calc(100% - 40px);
    bottom: 0px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  @media (max-width: 767px) {
    border: 5px solid ${({ theme }) => theme.colors.colorGreyLightAlt};
    width: calc(100% - 40px);
    position: relative;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
  
  @media (max-width: 575px) {
  }
`;

class SearchPage extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const {
      isSearching: wasSearching,
    } = this.props;
    const {
      isSearching,
      history,
    } = nextProps;
    if (!wasSearching && isSearching) {
      history.push('/bestil/soeg');
    }
  }

  componentWillUnmount() {
    const { isSearching } = this.props;
    if (isSearching) {
      clearSearch();
    }
  }

  onChange(evt) {
    const { target: { value } } = evt;
    const { changeSearch } = this.props;
    changeSearch(value);
  }

  render() {
    const { term } = this.props;
    return (
      <InputWrapper>
        <Input
          placeholder="Søg varer"
          type="text"
          value={term}
          onChange={this.onChange}
        />
      </InputWrapper>
    );
  }
}
const selector = createSelector(
  getIsSearching,
  getSearchTerm,
  (isSearching, term) => ({
    isSearching,
    term,
  }),
);

export default withRouter(connect(
  selector,
  {
    clearSearch,
    changeSearch,
  },
)(SearchPage));
