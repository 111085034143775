import React from 'react';
import styled from 'styled-components/macro';
// import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setPreferredDeliveryType, getPreferredDeliveryType } from '../../../../features/commerce/delivery';
import { deliveryTypeError } from '../../../../features/commerce/selectors';
import { Container, Col, Row } from '../../../../components/grid';
import {
  Paragraph, Heading1, ClearFix,
} from '../../../../components/typography';
import CategorySelector from './CategorySelector';
import { BucketPreFinish } from '../../../../containers/commerce';
import { BucketView } from '../../../../components/commerce/Bucket';
import { screenSizeSwitch } from '../../../../lib/helpers';
import Footer from '../../../../layout/DefaultLayout/Footer';

const VisibleBucketDisplay = ({ display }) => (
  <div style={{ height: '100%' }}>
    <div style={{ position: 'sticky', top: 0 }}>
      <BucketPreFinish display={display} />
    </div>
  </div>
);

const BucketContainer = screenSizeSwitch([[991, () => null], VisibleBucketDisplay]);

const RenderHeading = styled.div`
  display: inline-block;
  position: relative;
  margin-top: -30px;

  @media (max-width: 991px) {
    margin-top: 0px;
  }

  @media (max-width: 575px) {
    display: block;
    text-align: center;
    & p {
      text-align: center;
    }
  }
`;
const TopContainer = styled(Container)`
  position: relative;
`;
const ItemsColNoStyle = ({
  children,
  ...otherProps
}) => (
  <Col {...otherProps}>
    {children}
  </Col>
);
const ItemsCol = styled(ItemsColNoStyle)`
  padding-right: 0px !important;
  
  @media (max-width: 991px) {
    padding-right: 15px !important;
  }
`;


const Step1Page = ({ children, tabComponent: Tabs, activeTab }) => (
  <Container white>
    <Row>
      <Col xs={12}>
        <CategorySelector />
      </Col>
    </Row>
    <Row>
      <Col xs={12} padding="0 15px">
        {Tabs && (
          <Tabs activeTab={activeTab} />
        )}
      </Col>
    </Row>
    <Row>
      <ItemsCol md={12} lg={9}>
        {children}
      </ItemsCol>
      <Col md={12} lg={3}>
        <BucketContainer display={BucketView} />
      </Col>
    </Row>
  </Container>
);
const maybeChangePreferredDeliveryType = (props) => {
  const {
    shouldTryPreferred,
    preferredDeliveryType,
    setPreferredDeliveryType,
  } = props;
  if (shouldTryPreferred) {
    setPreferredDeliveryType(preferredDeliveryType);
  }
};

class MainBestilLayout extends React.Component {
  componentWillMount() {
    maybeChangePreferredDeliveryType(this.props);
  }

  componentWillReceiveProps(nextProps) {
    maybeChangePreferredDeliveryType(nextProps);
  }

  render() {
    const {
      children,
      tabComponent,
      step,
      activeTab,
    } = this.props;
    return (
      <React.Fragment>
        <TopContainer>
          <Row>
            <Col xs={12} paddingTop="0px">
              <RenderHeading>
                <Heading1 marginBottom="0px">{step === 2 ? ('Kasse') : ('Nem bestilling')}</Heading1>
                {step === 2 ? ('') : (
                  <React.Fragment>
                    <Paragraph margin="0">Til privatpersoner, firmaer &amp; offentlige virksomheder (EAN)</Paragraph>
                  </React.Fragment>
                )}
              </RenderHeading>
              { /* <StepDisplay step={step} /> */ }
              <ClearFix />
            </Col>
          </Row>
        </TopContainer>
        {step === 1
          ? (
            <Step1Page tabComponent={tabComponent} activeTab={activeTab}>
              {children}
            </Step1Page>
          )
          : (
            <Container white>
              <Row>
                <Col xs={12}>
                  {children}
                </Col>
              </Row>
            </Container>
          )
        }
        <Footer />
      </React.Fragment>
    );
  }
}
export default connect(
  (state, { preferredDeliveryType }) => {
    const currentPreferredDeliveryType = getPreferredDeliveryType(state);
    return {
      shouldTryPreferred:
        preferredDeliveryType
        && preferredDeliveryType !== currentPreferredDeliveryType
        && !deliveryTypeError(state, preferredDeliveryType),
    };
  },
  {
    setPreferredDeliveryType,
  },
)(MainBestilLayout);
